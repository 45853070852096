import React from "react";
import { faBan, faCirclePlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { IUsuarios } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import "./style.css";

export function Usuarios() {
  const navigate = useNavigate();
  const { tokens, checkToken, usuario } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [list, setList] = useState<IUsuarios[]>([]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setTxtLoading("Carregando lista de usuários...");

    const canLoad = await checkToken();
    if (!canLoad) return;

    await api
      .get("usuarios")
      .then((res) => {
        if (!res.data) return;

        setList(res.data.result);
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response?.data?.msg);
        else
          alert(
            "Erro ao tentar recuperar a lista de usuários. Tente novamente mais tarde."
          );
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }, [checkToken]);

  useEffect(() => {
    if (tokens?.token) loadList();
  }, [tokens, loadList]);

  useEffect(() => {
    document.title = "ADM - Usuários";
  }, []);

  return (
    <div id="pgUsuarios">
      {!usuario?.admin && (
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <FontAwesomeIcon
            icon={faBan}
            style={{ fontSize: "70px", color: "#ff5349" }}
          />
          <h2 style={{ marginTop: "15px" }}>USUÁRIO SEM PERMISSÃO</h2>
        </div>
      )}

      {loading && <Load txtLoading={txtLoading} />}

      {!loading && usuario?.admin && (
        <>
          <div className="topo">
            <div style={{ flex: 1 }} />
            <button
              onClick={() => navigate("novo")}
              className="btnNovo btn-green"
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              NOVO
            </button>
          </div>

          <div className="painel table">
            <div className="head">
              <span style={{ width: "45px" }}></span>
              <span style={{ flex: "2" }}>Nome</span>
              <span style={{ flex: "2" }}>Usuário</span>
              <span style={{ flex: "1", justifyContent: "center" }}>
                Admin?
              </span>
            </div>

            <div className="tableContent">
              {list &&
                list.map((item) => (
                  <div className="rowTable" key={`u-${item.id}`}>
                    <button
                      className="btn-purple btnSmall"
                      onClick={() => navigate(`${item.id}`)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <span style={{ flex: "2" }}>{item.nome}</span>
                    <span style={{ flex: "2" }}>{item.email}</span>
                    <span style={{ flex: "1", justifyContent: "center" }}>
                      {item.admin === 1 ? "Sim" : "Não"}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
