import {
  faCirclePlus,
  faEdit,
  faImages,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { KeyboardEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { Pagination } from "../../components/Pagination";
import { ICursos } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import {
  convertDateSqlToDate,
  convertDateToBr,
} from "../../utils/dateProvider";
import Modal from "react-modal";

import "./style.css";
import { defaultModalStyle } from "../../utils/defaultModalStyle";
import { CursoImages } from "../../modals/CursoImages";

export function Cursos() {
  const navigate = useNavigate();
  const { tokens, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [list, setList] = useState<ICursos[]>([]);
  const [pesquisa, setPesquisa] = useState("");

  const [modalImages, setModalImages] = useState(false);
  const [editCurso, setEditCurso] = useState<ICursos | undefined>();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const limitPage = 25;

  const loadList = useCallback(
    async (pesq?: string) => {
      setLoading(true);
      setTxtLoading("Carregando lista...");

      await api
        .get(
          `cursos?limit=${limitPage}&page=${page}&filter_nome=${
            pesq ? pesq : ""
          }`
        )
        .then((res) => {
          if (!res.data) return;

          setList(res.data.result);

          const newMaxPage = Math.ceil(res.data.total / limitPage);
          setMaxPage(newMaxPage < 1 ? 1 : newMaxPage);
        })
        .catch((err) => {
          if (err.response?.data?.msg) alert(err.response.data.msg);
          else
            alert(
              "Erro ao tentar recuperar a lista. Tente novamente mais tarde"
            );
        })
        .finally(() => {
          setLoading(false);
          setTxtLoading("");
        });

      const canLoad = await checkToken();
      if (!canLoad) return;
    },
    [checkToken, page]
  );

  const handlePesquisaKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") loadList();
  };

  const handleDelete = async (item: ICursos) => {
    if (!window.confirm(`Deseja realmente excluir ${item.nome}?`)) return;

    setLoading(true);
    setTxtLoading("Excluindo cadastro...");

    const canLoad = await checkToken();
    if (!canLoad) return;

    try {
      await api.delete("cursos", {
        data: { id: item.id },
      });

      loadList(pesquisa);
    } catch (err: any) {
      if (err.response?.data?.msg) alert(err.response?.data?.msg);
      else alert("Erro ao excluir o cadastro. Tente novamente mais tarde");

      setLoading(false);
      setTxtLoading("");
    }
  };

  const handleOpenModalImages = (item: ICursos) => {
    setEditCurso(item);
    setModalImages(true);
  };

  useEffect(() => {
    if (tokens?.token) loadList();
  }, [tokens, loadList]);

  useEffect(() => {
    document.title = "ADM - Cursos";
    Modal.setAppElement("body");
  }, []);

  return (
    <div id="pgCursos">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && (
        <>
          <div className="topo">
            <Input
              mask="none"
              value={pesquisa}
              inputMaskChange={(v) => {
                setPesquisa(v);
              }}
              placeholder="Pesquisa por nome"
              onKeyUp={(e) => handlePesquisaKeyUp(e)}
            />

            <button
              onClick={() => {
                loadList(pesquisa);
              }}
              className="btn-purple"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>

            <button
              onClick={() => navigate("novo")}
              className="btnNovo btn-green"
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              NOVO
            </button>
          </div>

          <div className="painel table">
            <div className="head">
              <span style={{ width: "147px" }}></span>
              <span style={{ flex: "2" }}>Nome</span>
              <span style={{ flex: "1" }}>Data Início</span>
              <span style={{ flex: "1" }}>Mínimo</span>
              <span style={{ flex: "1" }}>Máximo</span>
            </div>

            <div className="tableContent">
              {list &&
                list.map((item) => (
                  <div className="rowTable" key={item.id}>
                    <button
                      className="btn-red btnSmall"
                      onClick={() => handleDelete(item)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>

                    <button
                      className="btn-purple btnSmall"
                      onClick={() => navigate(`${item.id}`)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>

                    <button
                      className="btn-blue btnSmall"
                      onClick={() => handleOpenModalImages(item)}
                    >
                      <FontAwesomeIcon icon={faImages} />
                    </button>
                    <span style={{ flex: "2" }}>{item.nome}</span>
                    <span style={{ flex: "1" }}>
                      {convertDateToBr(convertDateSqlToDate(item.data_inicio))}
                    </span>
                    <span style={{ flex: "1" }}>{item.minimo}</span>
                    <span style={{ flex: "1" }}>{item.maximo}</span>
                  </div>
                ))}
            </div>
          </div>

          <Pagination maxPage={maxPage} page={page} setPage={setPage} />
        </>
      )}

      <Modal isOpen={modalImages} style={defaultModalStyle}>
        <CursoImages
          closeModal={() => {
            setModalImages(false);
            setEditCurso(undefined);
          }}
          curso={editCurso}
        />
      </Modal>
    </div>
  );
}
