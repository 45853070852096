import React from "react";
import DatePicker, { DatePickerProps } from "react-date-picker";
import TimePicker, { TimePickerProps } from "react-time-picker";

import "./style.css";

// type Props = DatePickerProps;

export function InputDate({ ...rest }: DatePickerProps) {
  return (
    <div id="dvDatePicker">
      <DatePicker className="customInputDate" format="dd/MM/y" {...rest} />
    </div>
  );
}

export function InputTime({ ...rest }: TimePickerProps) {
  return (
    <div id="dvTimePicker">
      <TimePicker className="customInputDate" {...rest} />
    </div>
  );
}
