import {
  faCartShopping,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Load } from "../../components/Load";
import { ICarrinho, ICursos } from "../../global/types";
import { useSiteContext } from "../../hooks/useSiteContext";
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertDateToBr, convertToDate } from "../../utils/dateProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./style.css";

type IParams = {
  id: string;
};

export function CursoSite() {
  const navigate = useNavigate();
  const { carrinho, saveCarrinho } = useSiteContext();
  const { id } = useParams() as IParams;

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [curso, setCurso] = useState<ICursos>({} as ICursos);
  const [images, setImages] = useState<string[]>([]);
  const [qtdVendas, setQtdVendas] = useState(0);

  const loadCurso = useCallback(async (id: number) => {
    setLoading(true);
    setTxtLoading("Carregando informações do curso");

    await api
      .get(`cursos/${id}`)
      .then(async (res) => {
        if (!res.data) return;

        setCurso(res.data);

        const reqQtdVendas = await api.get(`cursos/qtdVendas?id_curso=${id}`);
        if (reqQtdVendas.data) setQtdVendas(reqQtdVendas.data);

        document.title = res.data.nome;

        loadImages(res.data);
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response.data.msg);
        else alert("Erro ao carregar informações do Curso");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }, []);

  const loadImages = useCallback(async (c: ICursos) => {
    await api
      .get(`cursos/getImgs?id_curso=${c.id}`)
      .then((res) => {
        if (!res.data) return;

        setImages(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.msg) alert(err.response.data.msg);
        else alert("Erro ao carregar images.");
      });
  }, []);

  const handleAddCarrinho = () => {
    let alreadyExistis: boolean =
      carrinho.filter((e) => e.curso.id === curso.id).length > 0;
    const newCarrinho: ICarrinho[] = carrinho.map((item) => {
      return item;
    });

    if (!alreadyExistis)
      newCarrinho.push({
        curso,
        quantidade: 1,
      });

    saveCarrinho(newCarrinho);
    navigate("/carrinho");
  };

  useEffect(() => {
    if (parseInt(id) > 0) loadCurso(parseInt(id));
  }, [id]);

  return (
    <div id="pgCursoSite">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && curso.id && (
        <>
          <div className="painel info">
            <h2>{curso.nome}</h2>

            {curso.capa && curso.capa.length > 4 && (
              <div className="capa">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${curso.id}/${curso.capa}`}
                  alt={curso.nome}
                />
              </div>
            )}

            <div className="description">
              {curso.descricao && (
                <>
                  <h4>Descrição do Curso</h4>
                  <hr />
                  {curso.descricao}
                  <hr />
                  <br />
                </>
              )}

              {curso.desistencia && (
                <>
                  <h4>Desistência do Curso</h4>
                  <hr />
                  {curso.desistencia}
                </>
              )}
            </div>

            <div className="imgsCarousel">
              <Carousel
                showThumbs={false}
                showArrows
                interval={3000}
                showStatus={false}
                autoPlay
                emulateTouch
                infiniteLoop
                autoFocus
                useKeyboardArrows
                dynamicHeight
              >
                {images.map((item) => (
                  <div key={item}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/archives/imgCursos/${curso.id}/${item}`}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="painel price">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2>{currencyFormatter(parseFloat(`${curso.valor}`))}</h2>
              <a
                href="https://www.google.com/maps/place/S%C3%ADtio+Art/@-21.6387385,-48.7818967,3491m/data=!3m1!1e3!4m13!1m7!3m6!1s0x94bed1e142f6f1b1:0x1ee4e9bc4c3bd685!2sIt%C3%A1polis,+SP,+14900-000!3b1!8m2!3d-21.5963276!4d-48.81109!3m4!1s0x94bed3187a5c68bf:0xe58c400350f596b8!8m2!3d-21.6422408!4d-48.7757461"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-ver-mapa"
              >
                <FontAwesomeIcon icon={faLocationDot} /> Ver no Mapa
              </a>
            </div>

            <div className="info-2">
              <strong>Data:</strong>
              {` ${convertDateToBr(convertToDate(curso.data_inicio))}`}
              <br />
              <strong>Hora:</strong> {` ${curso.hora_inicio.substring(0, 5)}`}
              <br />
              <strong>Carga Horária:</strong> {curso.carga_horaria} hrs
              <br />
              <strong>Vagas Totais:</strong> {curso.maximo}
              <br />
              <strong>Vagas Disponíveis:</strong> {curso.maximo - qtdVendas}
            </div>
            <button
              onClick={() => {
                handleAddCarrinho();
              }}
            >
              <FontAwesomeIcon icon={faCartShopping} />
              COMPRAR
            </button>
          </div>
        </>
      )}
    </div>
  );
}
