import { faCheck, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/Input";
import { InputDate, InputTime } from "../../components/InputDate";
import { Load } from "../../components/Load";
import { ICursos } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";
import {
  convertDateSqlToDate,
  convertDateToUs,
  convertToDate,
  convertToUs,
} from "../../utils/dateProvider";
import { maskNumber } from "../../utils/masks";

import "./style.css";

type ParamsProps = {
  tipo: "new" | "edit";
};

type IParams = {
  id: string;
};

export function CursosForm({ tipo }: ParamsProps) {
  const navigate = useNavigate();

  const { id } = useParams() as IParams;
  const { tokens, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cadastro, setCadastro] = useState<ICursos>({} as ICursos);

  const loadCadastro = useCallback(async () => {
    setLoading(true);
    setTxtLoading("Carregando cadastro...");

    const canLoad = await checkToken();
    if (!canLoad) return;

    await api
      .get(`cursos/${id}`)
      .then((res) => {
        if (!res.data) return;

        const cad = res.data as ICursos;

        setCadastro({
          ...cad,
          data_inicio: convertToUs(`${cad.data_inicio}`),
          valor: currencyFormatter(parseFloat(`${cad.valor}`), false),
        });
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response.data.msg);
        else alert("Erro ao carregar cadastro. Tente novamente mais tarde");
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }, [checkToken, id]);

  const handleSalvar = async () => {
    setLoading(true);
    setTxtLoading("Salvando cadastro...");

    const canLoad = await checkToken();
    if (!canLoad) return;

    await api
      .post(`cursos`, {
        ...cadastro,
        valor: parseFloat(
          `${cadastro.valor}`.replace(".", "").replace(",", ".")
        ),
      })
      .then((res) => {
        if (!res.data) return;

        navigate("/adm/cursos");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  };

  useEffect(() => {
    if (tokens?.token && tipo === "edit") loadCadastro();
  }, [tokens, tipo, loadCadastro]);

  return (
    <div id="pgCursosForm">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && (
        <>
          <div className="painel form">
            <p className="title">
              {tipo === "new" ? "Novo" : "Editando"} Cadastro
            </p>

            <div style={{ width: "50%" }}>
              <label>Nome</label>
              <Input
                mask="none"
                value={cadastro.nome ? cadastro.nome : ""}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, nome: v }))}
                placeholder="Nome do Curso"
              />
            </div>

            <div style={{ width: "100%" }}></div>

            <div style={{ width: "25%" }}>
              <label>Data</label>
              <InputDate
                value={
                  cadastro.data_inicio
                    ? convertToDate(cadastro.data_inicio)
                    : null
                }
                onChange={(v: any) =>
                  setCadastro((e) => ({
                    ...e,
                    data_inicio: v !== null ? convertDateToUs(v) : "",
                  }))
                }
                required
              />
            </div>

            <div style={{ width: "25%" }}>
              <label>Hora</label>
              <InputTime
                value={cadastro.hora_inicio ? cadastro.hora_inicio : ""}
                onChange={(v: any) =>
                  setCadastro((e) => ({
                    ...e,
                    hora_inicio: v,
                  }))
                }
                required
              />
            </div>

            <div style={{ width: "20%" }}>
              <label>Valor</label>
              <Input
                mask="currency"
                value={cadastro.valor ? cadastro.valor : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, valor: v }))
                }
                placeholder="Valor do Curso"
                type="tel"
              />
            </div>

            <div style={{ width: "100%" }}></div>

            <div style={{ width: "15%" }}>
              <label>Carga Horária</label>
              <Input
                mask="number"
                value={
                  cadastro.carga_horaria
                    ? maskNumber(`${cadastro.carga_horaria}`)
                    : ""
                }
                inputMaskChange={(v) =>
                  setCadastro((e) => ({
                    ...e,
                    carga_horaria: parseInt(v.replace(/\D/g, "")),
                  }))
                }
                placeholder="Carga Horária"
                type="tel"
                maxLength={5}
              />
            </div>

            <div style={{ width: "15%" }}>
              <label>Venda Mínima</label>
              <Input
                mask="number"
                value={cadastro.minimo ? maskNumber(`${cadastro.minimo}`) : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({
                    ...e,
                    minimo: parseInt(v.replace(/\D/g, "")),
                  }))
                }
                placeholder="Quantidade de Venda Mínima"
                type="tel"
              />
            </div>

            <div style={{ width: "15%" }}>
              <label>Venda Máxima</label>
              <Input
                mask="number"
                value={cadastro.maximo ? maskNumber(`${cadastro.maximo}`) : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({
                    ...e,
                    maximo: parseInt(v.replace(/\D/g, "")),
                  }))
                }
                placeholder="Quantidade de Venda Máxima"
                type="tel"
              />
            </div>
            <div style={{ width: "99.5%" }}>
              <label>Descrição do Curso</label>
              <textarea
                style={{ height: 275 }}
                value={cadastro.descricao ? cadastro.descricao : ""}
                onChange={(v) =>
                  setCadastro((e) => ({ ...e, descricao: v.target.value }))
                }
              />
            </div>
            <div style={{ width: "99.5%" }}>
              <label>Desistência do curso</label>
              <textarea
                style={{ height: 275 }}
                value={cadastro.desistencia ? cadastro.desistencia : ""}
                onChange={(v) =>
                  setCadastro((e) => ({ ...e, desistencia: v.target.value }))
                }
              />
            </div>
          </div>

          <div className="btnPainel">
            <button
              className="btn-purple"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faUndo} />
              VOLTAR
            </button>

            <button
              className="btn-green"
              onClick={() => {
                handleSalvar();
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              SALVAR
            </button>
          </div>
        </>
      )}
    </div>
  );
}
