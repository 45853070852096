import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IStorageAdm, ITokens, IUsuarioLogado } from "../global/types";
import { api } from "../services/api";

export type AdmContextType = {
  tokens: ITokens | undefined;
  usuario: IUsuarioLogado | undefined;
  saveTokens: (token: string, user: IUsuarioLogado) => void;
  logout: () => void;
  checkToken: () => Promise<boolean>;
};

type ContextProps = {
  children: ReactNode;
};

export const AdmContext = createContext({} as AdmContextType);

export function AdmContextProvider(props: ContextProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [tokens, setTokens] = useState<ITokens>({} as ITokens);
  const [usuario, setUsuario] = useState<IUsuarioLogado>({} as IUsuarioLogado);

  const saveTokens = (token: string, user: IUsuarioLogado) => {
    setUsuario(user);
    setTokens({ token });

    const lsStringify = JSON.stringify({
      tokens: { token },
      user,
    });

    api.defaults.headers.common["Authorization"] = token;
    localStorage.setItem(`regenerativa`, lsStringify);
  };

  const logout = () => {
    localStorage.removeItem("regenerativa");
    setTokens({} as ITokens);
    setUsuario({} as IUsuarioLogado);
    navigate("/login");
  };

  const checkToken = useCallback(async (): Promise<boolean> => {
    if (pathname.includes("/login")) return true;

    try {
      await api.post("login/verifica_token");
      return true;
    } catch (err: any) {
      navigate("/login");
      return false;
    }
  }, [navigate, pathname, tokens, usuario]);

  useEffect(() => {
    if (!pathname.includes("/adm/")) return;

    const storage = localStorage.getItem(`regenerativa`);

    if (storage) {
      const lsParse = JSON.parse(storage) as IStorageAdm;

      api.defaults.headers.common["Authorization"] = lsParse.tokens.token;
      setTokens(lsParse.tokens);
      setUsuario(lsParse.user);
    } else navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (tokens.token) checkToken();
  }, [tokens, checkToken]);

  return (
    <AdmContext.Provider
      value={{
        tokens,
        usuario,
        saveTokens,
        logout,
        checkToken,
      }}
    >
      {props.children}
    </AdmContext.Provider>
  );
}
